.split-screen-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    height: 85vh;
    width: 100%;
}

@media (max-width: 1024px) {
    .split-screen-container {
        flex-direction: column;
        height: auto;
    }
}