.file-viewer-container {
    margin-top: 40px;
}

.file-container {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 30px;
}

.file-container__file {
    padding: 20px;
    width: 100%;
    max-width: 300px;
}

.file-container__file > div {
    margin: 10px 0;
}

.file-container__file--new {
    border-top: 5px solid var(--success-color);
    order: 2;
}
.file-container__file--aged {
    border-top: 5px solid var(--warning-color);
    order: 2;
}
.file-container__file--old {
    border-top: 5px solid var(--error-color);
    order: 5;
}
.file-container__file--loading {
    border-top: 5px solid transparent;
    order: 5;
}

.dynamic-container {
    flex-basis: 100%;
    max-width: initial;
    padding: 0;
    display: flex;
    gap: 20px;
}

.dynamic-container--first {
    order: 1;
}

.dynamic-container--second {
    order: 3;
}

.dynamic-container--placeholder {
    order: initial;
}

.dynamic-container__heading {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.dynamic-container__reference-color {
    width: 20px;
    height: 15px;
    border-radius: 3px;
    margin-right: 10px;
}

.dynamic-container__reference-color--new {
    background-color: var(--success-color);
}

.dynamic-container__reference-color--aged {
    background-color: var(--warning-color);
}

.dynamic-container__reference-color--old {
    background-color: var(--error-color);
}