.profile-navigation-container {
    margin: 30px 0;
    width: 100%;
    max-width: 550px;
}

@media (max-width: 1024px) {
    .profile-navigation-container__navigation {
        flex-wrap: wrap;
        gap: 20px 0;
        height: auto !important;
    }
}