.logo-container--row {
    display: flex;
    flex-direction: row;
    gap: 50px;
    align-items: center;
    justify-content: center;
}

.logo-container__image {
    width: 130px;
}

.logo-container__name {
    font-size: 5em;
}

.logo-container__motto {
    font-size: 20px;
}

@media (max-width: 1024px) {
    .logo-container--row {
        display: block;
    }

    .logo-container {
        text-align: center;
    }

    .logo-container__image {
        margin-top: 30px;
        width: 50px;
    }

    .logo-container__name {
        font-size: 3em;
    }
    
    .logo-container__motto {
        font-size: 0.9em;
    }
}