.vending-machines-selection-options {
    margin: 20px 0;
}

.vending-machines-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 30px;
    margin-bottom: 30px;
}

.vending-machines-container__vending-machine {
    width: 200px;
    padding: 20px;
    cursor: pointer;
}

.vending-machines-container__header {
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: space-between;
}

.vending-machines-group-container {
    padding: 0 20px 20px 20px;
}

.vending-machines-group-container__headline {
    margin: 20px 0;
}

.vending-machines-group-container__vending-machines-container {
    display: flex;
    flex-direction: row;
    gap: 30px;
    flex-wrap: wrap;
    max-width: 430px;
}
