.kickoff__text-block {
    max-width: 800px;
    margin: 16px auto;
}

.kickoff__big-image {
    margin: 20px auto;
    width: 100%;
    max-width: 800px;
}

.all-in-one-container {
    margin: 50px 0;
    text-align: center;
}

.request-offer-container {
    margin: 50px 0;
    text-align: center;
}

.request-offer-container__contact-information {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.request-offer-container__contact-information > div, .request-offer-container__contact-information > a {
    padding: 10px;
    margin: 10px 25px;
    background-color: var(--main-theme-color);
    color: var(--site-background-color);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 190px;
}

.auto-billing-container {
    text-align: center;
}

.supported-providers-container {
    margin: 50px 0;
    text-align: center;
}

.supported-providers-container__headline {
    margin-bottom: 30px;
}

.supported-providers-container__provider-images {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.supported-providers-container__provider-images img {
    width: 150px;
    margin: 0 20px 20px 20px;
}