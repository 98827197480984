.info-card-container {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    /*justify-content: space-evenly;*/
    gap: 30px;
    flex-wrap: wrap;
}

.info-card-container__card {
    width: fit-content;
    min-width: 200px;
    padding: 10px;
}

.info-card-container__card svg {
    font-size: 2em;
}

.info-card-container__card > p {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
}