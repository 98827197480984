.auth-container {
    width: 400px;
}

.auth-container__alert {
    width: 100%;
}

.auth-container__form {
    padding: 50px;
    width: 100%;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
    background-color: var(--site-background-color);
}

.auth-container__footer {
    width: 100%;
    text-align: center;
}