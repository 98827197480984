@font-face {
  font-family: "MontserratAlternates";
  src: url("../../public/fonts/MontserratAlternates-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SegoeUI";
  src: url("../../public/fonts/SegoeUI-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

:root {
  --main-theme-color: #1976d2;
  --background-color: #f2f2f2;
  --site-background-color: #fff;
  --font-color: #000;
  --success-color: #2e7d32;
  --warning-color: #ffa729;
  --error-color: #ef5350;
  --main-font-family: "SegoeUI";
  --logo-font-family: "MontserratAlternates";
  --container-width: 1536px;
}

* {
  box-sizing: border-box;
}

body {
  overflow-y: scroll;
  margin: 0;
  font-family: var(--main-font-family), sans-serif;
  background-color: var(--background-color);
}

/*.firebase-emulator-warning {
  display: none !important;
}*/

.logo-font {
  font-family: var(--logo-font-family) !important;
  text-decoration: none;
  color: inherit;
}

.logo-font--small {
  font-size: 1rem;
}

.big-text {
  font-size: 1.1rem;
}

.medium-text {
  font-size: 0.8rem;
}

.small-text {
  font-size: 0.6rem;
}

.href-link {
  color: inherit;
  text-decoration: underline;
} 

.hidden-href-link {
  color: inherit;
  text-decoration: none;
}

.unavailable {
  opacity: 0.5;
}

.backdrop-loading {
  z-index: 10;
  color: var(--font-color);
  background-color: var(--site-background-color) !important;
}