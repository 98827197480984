.footer-container {
    max-width: var(--container-width);
    margin: 0 auto 30px auto;
    padding: 20px;
    display: flex;
    flex-direction: row;
    gap: 50px;
    /*
    width: 100%;
    background-color: var(--site-background-color);
    justify-content: space-evenly;
    */
}

.footer-container--login {
    width: 100%;
    text-align: center;
    position: absolute;
    bottom: 50px;
}

.footer-container__image {
    margin: 10px 0 0 0;
    width: 110px;
}

@media (max-width: 1024px) {
    .footer-container--login {
        bottom: auto;
        padding-bottom: 20px;
    }
}