.page-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.page-container__inner {
    flex: 1;
}

.main-container {
    max-width: var(--container-width);
    margin: 30px auto;
    padding: 50px 30px;
    background-color: var(--site-background-color);
}

.main-container__alert {
    margin-bottom: 30px;
}