.feedback-container {
    margin: 16px 0;
    height: 50px;
    display: flex;
    align-items: center;
}

.feedback-container__info {
    width: fit-content;
}

.progress-container {
    width: 100%;
}

.progress-container__bar {
    width: 100%;
    max-width: 500px;
}

.progress-container__status {
    margin-top: 10px;
}

.form-section {
    margin: 35px 0;
}

.field-row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 15px 0;
}

.field-row > div {
    margin: 10px;
}

.field-row--slider {
    align-items: center;
}

.field-row__label {
    width: 200px;
}

.field-row__special-wrapper {
    width: 200px;
    display: flex;
}

.form-table {
    margin: 30px 0;
}

.form-table th {
    font-weight: bold;
}

.form-table--vending-machines {
    width: 100%;
    min-width: 1000px;
}
.form-table--groups {
    max-width: 700px;
    min-width: 600px;
}

.multiple-button-container {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    gap: 20px;
}

.multiple-button-container--row {
    flex-direction: row;
}

.button-wrapper__text {
    margin-left: 6px;
}

@media (max-width: 1024px) {
    .form-table--vending-machines th:nth-child(2), .form-table--vending-machines td:nth-child(2) {
        display: none;
    }
}