.init-login-container {
    width: 100%;
}

.stepper-container {
    margin: 30px auto;
    /*word-break: break-word;*/
}

.stepper-info {
    width: fit-content;
    margin: 16px 0;
}

.button-container {
    display: flex;
    flex-direction: row;
    padding-top: 16px;
}

.button-container__placeholder {
    flex: 1 1 auto;
}