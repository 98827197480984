.navbar img {
    width: 35px;
    margin-right: 10px;
}

.navbar .logo-font {
    margin-right: 16px;
    letter-spacing: .3rem;
    font-size: 1.8rem;
}

.navbar-button-container {
    flex-grow: 1;
}

.navbar-button-container__button {
    margin-top: 16px;
    margin-bottom: 16px;
    color: white !important;
    display: block;
}

.navbar__profile-container {
    display: flex;
    align-items: center;
    gap: 10px;
}

.navbar__link {
    display: contents;
}